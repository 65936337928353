<template>
  <ZModal
    :visible="showModal"
    padded
    hide-footer
    no-close-on-backdrop
    :title="title"
    @hide="onHideModal"
    @hidden="hiddenModal"
  >
    <p
      v-if="subtitle"
      class="text-center subtitle"
    >
      {{ subtitle }}
    </p>

    <ZOverlay :show="isExternalAuthenticationLoading">
      <LazyFormSignup
        v-if="showEmailForm === AUTHENTICATION_TYPE.Signup"
        :owner-signup="isOwnerPage"
      />

      <LazyFormLogin v-else-if="showEmailForm === AUTHENTICATION_TYPE.Login" />

      <template v-else>
        <p class="text-center toggle-text">
          <template v-if="isSignupVisible">
            {{ t('auth.haveAnAccount') }}
            <a
              :href="localePath('index-signin')"
              @click.prevent="toggleAuth()"
            >{{ t('auth.login') }}</a>
          </template>
          <template v-else>
            {{ t('auth.noAccount') }}
            <a
              :href="localePath('index-signup')"
              @click.prevent="toggleAuth()"
            >{{ t('auth.signUp') }}</a>
          </template>
        </p>

        <FormAuthProviders
          :auth-type="authType"
          :intent="authenticationIntent"
          class="mb-4"
          @show-login-form="updateEmailForm(AUTHENTICATION_TYPE.Login)"
          @show-signup-form="updateEmailForm(AUTHENTICATION_TYPE.Signup)"
        />
      </template>
    </ZOverlay>

    <i18n-t
      v-if="isSignupVisible"
      keypath="auth.terms.text"
      tag="p"
      class="mt-4 mb-0 terms"
    >
      <a
        target="_blank"
        :href="t('auth.terms.yearsOldLink')"
      >{{ t('auth.terms.yearsOld') }}</a>
      <NuxtLink
        target="_blank"
        :to="localePath('terms-service')"
      >
        {{ t('auth.terms.termsOfService') }}
      </NuxtLink>
      <NuxtLink
        target="_blank"
        :to="localePath('privacy-policy')"
      >
        {{ t('auth.terms.privacyPolicy') }}
      </NuxtLink>
    </i18n-t>
  </ZModal>
</template>

<script setup lang="ts">
import { AUTHENTICATION_INTENT, AUTHENTICATION_PROVIDERS, AUTHENTICATION_TYPE } from '~/constants/authentication'

import { AuthProviders as signupTypes } from '~/lib/auth'
import { trackSignupViewed, trackSignupExited } from '~/lib/tracking'
import type { Nullable } from '~/types'
import type { SignupExitedParamsResponseEnum } from '~/types/tracking'

const { t } = useI18n({
  useScope: 'local',
})

const localePath = useLocalePath()
const { routeBaseName } = useBaseName()

const isOwnerPage = computed(() => routeBaseName.value === 'owner')

const showEmailForm = ref<Nullable<AUTHENTICATION_TYPE>>(null)

function updateEmailForm(value: AUTHENTICATION_TYPE) {
  showEmailForm.value = value
}

const { authenticationIntent, isSignupVisible } = useSignup()
const { title, subtitle } = useAuthContent()
const { authType, showModal, hiddenModal, toggleAuth } = useAuthModal({ showEmailForm })
const { isExternalAuthenticationLoading } = useExternalAuthentication()

const { onHideModal } = useAuthModalSignupTracking({
  routeBaseName,
  showEmailForm,
  isOwner: isOwnerPage.value,
})

onBeforeUnmount(() => {
  const { isLoggedIn } = useAuthentication()
  if (showModal.value && !isLoggedIn.value) {
    onHideModal({ trigger: 'navigation' })
  }
})

function useAuthContent() {
  const { authenticationIntent, enquiryOwnerName, isSignupVisible } = useSignup()

  const title = computed(() => {
    switch (authenticationIntent.value) {
      case AUTHENTICATION_INTENT.BookingIntent:
        return t('auth.bookingIntentTitle')
      case AUTHENTICATION_INTENT.EnquiryIntent:
        return t('auth.enquiryIntentTitle')
      case AUTHENTICATION_INTENT.ListingIntent:
        return t('auth.listYourRV')
      default:
        if (isSignupVisible.value) {
          return t('auth.createAnAccount')
        }
        return t('auth.loginToRVezy')
    }
  })

  const subtitle = computed(() => {
    switch (authenticationIntent.value) {
      case AUTHENTICATION_INTENT.EnquiryIntent:
        return t('auth.enquiryIntentSubtitle', { name: enquiryOwnerName.value })
      default:
        return ''
    }
  })

  return { title, subtitle }
}

function useAuthModal({ showEmailForm }: { showEmailForm: Ref<Nullable<AUTHENTICATION_TYPE>> }) {
  const showModal = ref(false)

  const { hideAuthModal, authenticationModalShown, authenticationProvider } = useAuthentication()
  watch(
    authenticationModalShown,
    (toVal) => {
      showModal.value = Boolean(toVal)
      showEmailForm.value
        = authenticationProvider.value === AUTHENTICATION_PROVIDERS.Email ? authenticationModalShown.value : null
    },
    { immediate: true },
  )

  function closeModal() {
    showModal.value = false
    showEmailForm.value = null

    // Update route if user is on signin route or signup route
    if (routeBaseName.value && ['index-signin', 'index-signup'].includes(routeBaseName.value)) {
      navigateTo(localePath('index'))
    }
  }

  function hiddenModal() {
    hideAuthModal()
    closeModal()
  }

  function toggleAuth() {
    const { showLogin } = useLogin()
    const { showSignup, authenticationIntent, enquiryOwnerName } = useSignup()

    if (authenticationModalShown.value === AUTHENTICATION_TYPE.Login) {
      showSignup({ intent: authenticationIntent.value, ownerName: enquiryOwnerName.value })
    }
    else if (authenticationModalShown.value === AUTHENTICATION_TYPE.Signup) {
      showLogin({ intent: authenticationIntent.value })
    }
  }

  return { authType: authenticationModalShown, hiddenModal, showModal, toggleAuth }
}
function useAuthModalSignupTracking({
  routeBaseName,
  showEmailForm,
  isOwner,
}: {
  routeBaseName: ComputedRef<string>
  showEmailForm: Ref<Nullable<AUTHENTICATION_TYPE>>
  isOwner: boolean
}) {
  const { isSignupVisible } = useSignup()
  const { isLoginVisible } = useLogin()
  watch(
    isSignupVisible,
    (newVal, oldVal) => {
      if (newVal) {
        trackSignupViewed({
          signupTypes,
          isOwner,
          cta: routeBaseName.value,
          pageSource: routeBaseName.value,
        })
      }
      else if (isLoginVisible.value && oldVal !== undefined) {
        trackSignupExitedEvent({
          isOwner,
          trigger: 'login',
          provider: showEmailForm.value ? 'email' : undefined,
        })
      }
    },
    {
      immediate: true,
    },
  )

  function onHideModal({ trigger }: { trigger: string }) {
    if (isSignupVisible.value) {
      trackSignupExitedEvent({
        isOwner,
        trigger: ['headerclose', 'esc'].includes(trigger) ? 'close' : trigger as SignupExitedParamsResponseEnum,
        provider: showEmailForm.value ? 'email' : undefined,
      })
    }
  }

  function trackSignupExitedEvent({ isOwner, trigger, provider }: { isOwner: boolean, trigger: SignupExitedParamsResponseEnum, provider: 'email' | undefined }) {
    trackSignupExited({
      isOwner,
      response: trigger,
      signupType: provider,
    })
  }

  return { onHideModal }
}
</script>

<style lang="scss" scoped>
p {
  @include caption;

  a {
    @include caption-strong;
    text-decoration: underline;
  }
}
.toggle-text a {
  color: getColor('primary-500');
}
.subtitle,
.terms {
  color: getColor('primary-350');

  a {
    @include caption-strong;
    color: getColor('primary-350');
  }
}
</style>

<i18n src="~/locales/common/auth.json" lang="json" />
